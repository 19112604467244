import { map } from 'lodash-es'

export class CanadianCitiesSearch {
  constructor(element) {
    this.element = $(element)
    this.action = this.element.data('action')
  }

  activate() {
    const locale = document.documentElement.lang || 'en'
    const minimumInputLength = 3
    const messages = {
      en: {
        inputTooShort: (term) =>
          `Please enter at least ${minimumInputLength - (term?.length ? term.length : 0)} characters`,
      },
      'fr-CA': {
        inputTooShort: (term) =>
          `Veuillez entrer au moins ${minimumInputLength - (term?.length ? term.length : 0)} caractères`,
      },
    }

    this.element.select2({
      minimumInputLength: minimumInputLength,
      formatInputTooShort: (term) => messages[locale].inputTooShort(term),
      allowClear: true,
      url: this.action,
      ajax: {
        url: this.action,
        dataType: 'json',
        delay: 250,
        cache: true,
        data: (text) => ({ q: { bell_api_city_name_cont: text } }),
        results: (data) => {
          const options = map(data.cities, (city) => this.formatSearchResultToOption(city))
          return { results: options }
        },
      },
      initSelection: (element, callback) => {
        const cityId = element.val()
        if (!cityId) return

        callback({ id: cityId, text: element.data('city-with-province') })
      },
    })
  }

  chooseOption(id) {
    // what if I need to clear?
    if (!id) return
    const url = `${this.action}/${id}`
    return $.getJSON(url).then((city) =>
      this.element.select2('data', this.formatSearchResultToOption(city))
    )
  }

  formatSearchResultToOption(result) {
    return {
      id: result.id,
      text: result.city_with_province,
    }
  }
}
